import React from "react"
import Layout from "../components/Layout";


export default () => {
  return (
    <Layout>
      <h1>
        Snippets
      </h1>
    </Layout>
  )
};